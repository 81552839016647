import { Component, OnDestroy, AfterViewInit, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @Input() dataAgrupado!: any;

  private map!: L.Map;

  private initMap(): void {
    this.map = L.map('map', {
      center: [-15.827, -49.836],
      zoom: 7,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);

    for (let key in this.dataAgrupado) {
      if (this.dataAgrupado.hasOwnProperty(key)) {
        const data = this.dataAgrupado[key];
        const { latitude, longitude, endereco } = data.chegada;

        const marker = L.marker([
          parseFloat(latitude || data.saida.latitude),
          parseFloat(longitude || data.saida.longitude),
        ]).bindPopup(
          `ID: ${data.chegada?.id || 'Não encontrado'} / ${
            data.saida?.id || 'Não encontrado'
          }<br>
           Funcionário: ${data.funcionario?.nome || 'Não encontrado'}<br>
           Data/hora Chegada: ${data.data_chegada || 'Não encontrado'}<br>
           Data/hora Saída: ${data.data_saida || 'Não encontrado'}<br>
           Latitude/Longitude Chegada: ${data.chegada?.latitude || ''} - ${
            data.chegada?.longitude || 'Não encontrado'
          }<br>
           Latitude/Longitude Saida: ${data.saida.latitude || ''} - ${
            data.saida.longitude || 'Não encontrado'
          }<br>
           Trecho: ${
             data.chegada?.endereco || data.saida?.endereco || 'Não encontrado'
           }<br>
           `
        );

        marker.addTo(this.map);
      }
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
