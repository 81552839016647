import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { FormLoginComponent } from './modules/login/form-login/form-login.component';
import { TermoPrivacidadeComponent } from './modules/termo-privacidade/termo-privacidade.component';

const routes: Routes = [
  { path: 'login', component: FormLoginComponent },
  { path: 'termo-privacidade', component: TermoPrivacidadeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
