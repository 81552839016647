import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ViagensService {
  constructor(private http: HttpClient) {}

  usuarios(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/usuarios`);
  }

  buscarPorFiltro(
    filtro: any,
    itemsPerPage: number,
    pageNumber: number
  ): Observable<any[]> {
    const httpParams = new HttpParams({
      fromObject: filtro,
    })
      .append('itemsPerPage', itemsPerPage.toString())
      .append('page', pageNumber.toString());

    return this.http.get<any[]>(`${environment.apiUrl}/registros/buscar`, {
      params: httpParams,
    });
  }

  abrirDocumento(userId: number, chave: string): void {
    this.http
      .get(
        `${environment.apiUrl}/registros/${userId}/${chave}/recibo-agrupado`,
        {
          responseType: 'blob',
        }
      )
      .subscribe((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      });
  }
}
