import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Color, LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { FuncoesAuxiliares } from 'src/app/utils/funcoesAuxiliares';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  totalViagens: any;
  totalFuncionarios: any;
  totalFuncionariosArray: any;
  dataAgrupado: any;
  totalChegadas: any;
  totalSaidas: any;

  selectFuncionario: any = [];
  filtroForm: FormGroup;

  constructor(
    private dashboardService: HomeService,
    public _fa: FuncoesAuxiliares,
    public dialog: MatDialog,
    private _fb: FormBuilder
  ) {
    this.filtroForm = this._fb.group({
      funcionario: [''],
    });
  }
  error: boolean = false;

  loading: boolean = false;

  view: [number, number] = [700, 300];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  legendPosition = LegendPosition.Below;
  xAxisLabel = 'Meses';
  showYAxisLabel = true;
  yAxisLabel = 'Infrações';

  colorScheme: Color = {
    domain: ['#007bff', '#6610f2', '#17a2b8'],
    group: ScaleType.Ordinal,
    selectable: true,
    name: 'custom colors',
  };

  ngOnInit() {
    this.getData();
    this.buscarSelect();
  }

  buscarSelect() {
    this.dashboardService.usuarios().subscribe((response: any) => {
      this.selectFuncionario = response;
    });
  }

  getData() {
    const filtro = this.filtroForm.value;
    this.loading = true;

    this.dashboardService.getData(filtro).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.totalViagens = response.total;
        this.totalFuncionarios = response.totalFuncionarios;
        this.dataAgrupado = response.dataAgrupado;
        this.totalChegadas = response.totalChegadas;
        this.totalSaidas = response.totalSaidas;

        this.totalFuncionariosArray = Object.values(
          response.totalFuncionarios
        ).map((item: any) => ({
          name: item.funcionario.nome,
          value: item.total_viagens,
        }));
      },
      error: (error: any) => {
        this.loading = false;
        this.error = true;
        console.error('Erro ao buscar dados', error);
      },
      complete: () => {
        // Completo: lidar quando o observable completar
      },
    });
  }

  onSelect(event) {
    console.log(event);
  }
}
